import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["hidden", "display"];

  connect() {
    // Only mask if there's a valid key (no errors)
    const hasError = this.displayTarget.classList.contains("border-red-500");
    if (this.hiddenTarget.value && !hasError) {
      this.maskValue();
    }
  }

  maskValue() {
    const value = this.hiddenTarget.value;
    if (value) {
      this.displayTarget.value =
        value.slice(0, 4) + "*".repeat(32) + value.slice(-4);
    }
  }

  focus() {
    if (this.displayTarget.value.includes("*")) {
      this.displayTarget.value = "";
    }
  }

  blur() {
    // If field is empty and we have a hidden value, show the masked version again
    if (!this.displayTarget.value && this.hiddenTarget.value) {
      this.maskValue();
    }
  }

  change(event) {
    this.hiddenTarget.value = event.target.value;
  }
}
